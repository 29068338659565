import { createContext, useReducer, useContext, PropsWithChildren } from "react";
import { Actions, IState } from "./types";
import { reducer } from "./reducer";
import { Inputs } from "../components/Form/Form";

interface IContextProps {
  state: IState;
  setProgress: (value: number) => void;
  setModalVisibility: (value: boolean) => void; 
  setFormData: (data: Inputs) => void,
  setPaymentStatus: (data: 'none' | 'success' | 'rejected') => void,
}

const initialState: IState = {
    progress: 0,
    modalVisibility: false,
    formData: undefined,
    paymentStatus: 'none',
};

const initContext: IContextProps = {
    state: initialState,
    setProgress: () => {},
    setModalVisibility: () => {},
    setFormData: () => {},
    setPaymentStatus: () => {}, 
}

const FormContext = createContext<IContextProps>(initContext);

export const useFormContext = () => {
    const context = useContext(FormContext)
    if (!context) throw new Error("useFormContext must be used within a CanvasContext")
    return context
}

export default function FormContextProvider({ children }: PropsWithChildren) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setProgress = (payload: number) => dispatch({ type: Actions.SET_PROGRESS, payload });

    const setModalVisibility = (payload: boolean) => dispatch({ type: Actions.SET_MODAL_VISIBILITY, payload });

    const setFormData = (payload: Inputs) => dispatch({type: Actions.SET_FORM_DATA, payload});

    const setPaymentStatus = (payload: 'none' | 'success' | 'rejected') => dispatch({type: Actions.SET_PAYMENT_STATUS, payload});


    const value = {
        state,
        setProgress,
        setModalVisibility,
        setFormData,
        setPaymentStatus,
    }

    return <FormContext.Provider value={value}>{children}</FormContext.Provider>
}
