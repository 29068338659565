import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import FormContextProvider from './store/formContext';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(4, 69, 175)',
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </FormContextProvider>
  </React.StrictMode>
);
