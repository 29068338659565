import React from 'react';
import { LinearProgress} from '@mui/material';
import { Form } from './components/Form/Form';
import { useFormContext } from './store/formContext';
import { PaymentResultPage } from './components/atoms/PaymentResultPage/PaymentResultPage';

import './base.scss';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: '24px 24px 12px 24px',
  borderRadius: "12px",
  border: '1px solid rgb(4, 69, 175)',
  maxHeight: '80vh',
  overflowY: 'scroll',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '20px'
};

const App = () => {
  const { state: { progress, paymentStatus } } = useFormContext();

  return (
    <div className='App'>
      {paymentStatus === 'none' &&
        <>
          <LinearProgress sx={{ position: 'fixed', top: '0', left: '0', width: '100%' }} variant="determinate" value={progress} />
          <Form />
        </>
      }
      {paymentStatus === 'success' &&
        <PaymentResultPage title={"Thank you, We've received your BondPay Application!"} subTitle='We are now processing your application' />
      }
      {paymentStatus === 'rejected' &&
        <PaymentResultPage title={"Something went wrong, try again"} />
      }
    </div>
  );
};

export default App;