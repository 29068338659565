import { FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material'
import React from 'react'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { IRadioData, InputTypes, Inputs } from '../../Form/Form';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

interface IFormItemRadio {
  control: Control<Inputs, any>;
  valueName: InputTypes;
  errors: FieldErrors<Inputs>;
  data: IRadioData[]
}

export const FormItemRadio: React.FC<IFormItemRadio> = ({ control, valueName, errors, data }) => {
  return (
    <Controller
      name={`${valueName}`}
      control={control!}
      rules={{ required: 'This field is required' }}
      render={({ field }) => (
        <RadioGroup
          {...field}
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
        >
          {data.map((item, index) => (
            <FormControlLabel key={index} value={item.value} control={<Radio />} label={item.label} />
          ))}
          {errors[valueName] && (
            <FormHelperText
              sx={{
                backgroundColor: 'rgb(247, 230, 230)',
                color: 'rgb(175, 4, 4)',
                padding: '4px 12px 4px 8px',
                borderRadius: '3px',
                maxWidth: 'fit-content',
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                minHeight: '28px',
              }}
            >
              <WarningRoundedIcon sx={{ width: '18px', height: '18px' }} />
              {errors[valueName]?.message}
            </FormHelperText>
          )}
        </RadioGroup>
      )}
    />
  )
}


