import { IState, Actions, IAction } from "./types";

export function reducer(state: IState, { type, payload }: IAction): IState {
    switch (type) {
        case Actions.SET_PROGRESS: {
            return {...state, progress: payload};
        }

        case Actions.SET_MODAL_VISIBILITY: {
            return {...state, modalVisibility: payload}
        }

        case Actions.SET_FORM_DATA: {
            return {...state, formData: payload}
        }

        case Actions.SET_PAYMENT_STATUS: {
            return {...state, paymentStatus: payload}
        }

        default: {
            return state
        }
    }
}

