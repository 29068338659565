import React from 'react'
import { Box, FormHelperText, MenuItem, Select, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { InputTypes, Inputs } from '../../Form/Form';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

interface IFormItemText {
  type: 'email' | 'string' | 'number' | 'phone' | 'address';
  placeholder?: string;
  control: Control<Inputs, any>;
  valueName: InputTypes
  errors: FieldErrors<Inputs>
}

export const FormItemText: React.FC<IFormItemText> = ({ type, placeholder, control, valueName, errors }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1000));

  return (
    <>
      {type === 'email' &&
        <Controller
          name={`${valueName}`}
          control={control!}
          rules={{
            required: 'This field is required',
            pattern: {
              value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
              message: 'Invalid email address',
            },
          }}
          render={({ field }) => (
            <TextField
              type='email'
              variant='standard'
              {...field}
            />
          )}
        />
      }

      {type === 'address' &&
        <Controller
          name={`${valueName}`}
          control={control!}
          rules={{
            required: 'This field is required',
            pattern: {
              value: /^[a-zA-Z0-9\s,.'-]*$/,
              message: 'Invalid address name',
            },
          }}
          render={({ field }) => (
            <TextField
              type='address'
              variant='standard'
              {...field}
            />
          )}
        />
      }

      {type === 'number' &&
        <Controller
          name={`${valueName}`}
          control={control!}
          rules={{
            required: 'This field is required',
            pattern: {
              value: /^[0-9]+$/,
              message: 'Invalid value',
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              type="phone"
              sx={{ maxWidth: '100%' }}
              placeholder={placeholder}
              variant="standard"
            />
          )}
        />
      }

      {type === 'string' &&
        <Controller
          name={`${valueName}`}
          control={control!}
          rules={{
            required: 'This field is required',
            pattern: {
              value: /^[a-zA-Z-_.,"' ]+$/,
              message: 'Invalid value',
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              sx={{ maxWidth: '100%' }}
              placeholder={placeholder}
              variant="standard"
            />
          )}
        />
      }

      {type === 'phone' &&
        <Box
          display={'flex'}
          gap={2}
          alignItems={'end'}
        >
          <Controller
            name={`${valueName}`}
            control={control!}
            rules={{
              required: 'This field is required',
              pattern: {
                value: /^[0-9_.+-]+$/,
                message: 'Invalid value',
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                type="phone"
                variant='standard'
                sx={{ width: `${isMobile ? '100%' : '50%'}` }} />
            )}
          />
        </Box>
      }
      
      {errors[valueName] && (
        <FormHelperText
          sx={{
            backgroundColor: 'rgb(247, 230, 230)',
            color: 'rgb(175, 4, 4)',
            padding: '4px 12px 4px 8px',
            borderRadius: '3px',
            maxWidth: 'fit-content',
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            minHeight: '28px',
          }}
        >
          <WarningRoundedIcon sx={{ width: '18px', height: '18px' }} />
          {errors[valueName]?.message}
        </FormHelperText>
      )}
    </>
  )
}

