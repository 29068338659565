import React, { ReactNode } from 'react'
import { Box, Button ,Typography, useMediaQuery, useTheme } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import './form-item.scss'
import { useFormContext } from '../../../store/formContext';
import { PayPal } from '../../atoms/PayPal/PayPal';

interface IFormItem {
  label: string;
  subLabel?: string;
  itemIndex: number;
  scroll?: () => void
  children: ReactNode;
  isSubmit?: boolean;
}
export const FormItem: React.FC<IFormItem> = ({ label, subLabel, itemIndex, scroll, isSubmit, children }) => {
  const { state: { modalVisibility } } = useFormContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <div className='form-item'>
      <Box
        width={'100%'}
        display={'flex'}
        alignItems={'start'}
        justifyContent={`${isMobile ? 'start' : 'center'}`}
        marginBlock={'32px 96px'}
        gap={1}
      >
        {isMobile ?
          <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', color: 'rgb(4, 69, 175)' }}>{itemIndex} <ArrowForwardIcon sx={{ height: '14px', width: '14px' }} /></Typography>
          :
          <Typography variant='body1' sx={{ display: 'flex', alignItems: 'center', color: 'rgb(4, 69, 175)' }}>{itemIndex} <ArrowForwardIcon sx={{ height: '20px', width: '20px' }} /></Typography>
        }
        <Box
          width={'65vw'}
          display={'flex'}
          flexDirection={'column'}
          gap={2}
        >
          <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'start'}
            gap={1}
            p={2}
          >
            {isMobile ?
              <>
                <Typography variant='h6' style={{ marginLeft: '-16px', marginTop: '-22px' }}>{label}</Typography>
                {subLabel && <Typography variant='body1' style={{ marginLeft: '-16px', color: "rgba(68, 68, 68, 0.8)" }}>{subLabel}</Typography>}
              </>
              :
              <>
                <Typography variant='h5' style={{ marginLeft: '-16px', marginTop: '-20px' }}>{label}</Typography>
                {subLabel && <Typography variant='h6' style={{ marginLeft: '-16px', color: "rgba(68, 68, 68, 0.8)" }}>{subLabel}</Typography>}
              </>
            }
          </Box>

          {children}

          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            gap={2}
          >
            {scroll &&
              <>
                <Button variant='contained' size='small' sx={{ fontSize: '20px', fontWeight: '600' }} onClick={() => scroll()}>
                  OK
                </Button>
                {!isMobile && <Typography variant='body2'>press <strong>Enter</strong> ↵ </Typography>}
              </>
            }
            {modalVisibility && isSubmit ?
              <PayPal />
              :
              <>
                {isSubmit &&
                  <>
                    <Button type="submit" variant="contained">
                      Submit
                    </Button>
                    {!isMobile && <Typography variant='body2'>press <strong>Enter</strong> ↵ </Typography>}
                  </>
                }
              </>
            }
          </Box>
        </Box>
      </Box>
    </div>
  )
}
