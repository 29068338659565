import { Box, Button, Typography } from '@mui/material'
import React, { FC } from 'react'
import { useFormContext } from '../../../store/formContext';

interface IPaymentResultPage {
    title: string;
    subTitle?: string;
}

export const PaymentResultPage: FC<IPaymentResultPage> = ({ title, subTitle }) => {
    const { state: { paymentStatus }, setPaymentStatus } = useFormContext()

    return (
        <Box
            width={'100vw'}
            height={'100vh'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={'10px'}
        >
            <Typography variant='h4' sx={{maxWidth: '65vw', textAlign: 'center'}}>{title}</Typography>
            {subTitle && <Typography variant='h5' sx={{widht: '100%', textAlign: 'center', maxWidth: '55vw', color: 'rgba(68, 68, 68, 0.8)'}}>{subTitle}</Typography>}
            {paymentStatus === 'rejected' && <Button variant='outlined' size='small' onClick={() => setPaymentStatus('none')}>Again</Button>}
        </Box>
    )
}