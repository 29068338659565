import React, { useCallback, useEffect } from "react";
import { useFormContext } from "../../../store/formContext";
import { Box, useMediaQuery, useTheme } from "@mui/material";

declare global {
  interface Window {
    paypal: any;
  }
}

export function PayPal() {
  const {
    state: { formData },
    setPaymentStatus,
    setModalVisibility,
  } = useFormContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  useEffect(() => {
    window.paypal.Messages().render("#paypal-message-container");
  }, []);

  return (
    <Box
      display={"flex"}
      alignItems={"start"}
      gap={"10px"}
      flexDirection={`${isMobile ? "column" : "row"}`}
      justifyContent={"start"}
    >
      <div
        id="paypal-button-container"
        ref={useCallback((el: any) => {
          if (!el) return;

          if (window.paypal) {
            window.paypal
              .Buttons({
                style: {
                  layout: "vertical",
                  color: "blue",
                  shape: "rect",
                  label: "installment",
                },
                fundingSource: window.paypal.FUNDING.PAYLATER,
                createOrder: (data: any, actions: any) => {
                  return actions.order.create({
                    intent: "CAPTURE",
                    purchase_units: [
                      {
                        description:
                          formData?.firstName! +
                          " " +
                          formData?.lastName! +
                          " " +
                          formData?.bond!,
                        amount: {
                          currency_code: "AUD",
                          value: +formData?.bond!,
                        },
                      },
                    ],
                  });
                },
                onApprove: (data: any, actions: any) => {
                  return actions.order.capture().then(async (details: any) => {
                    setModalVisibility(false);
                    setPaymentStatus("success");
                    try {
                      const response = await fetch("/api/sendEmail", {
                        method: "GET",
                      });
                      const result = await response.json();
                      if (response.ok) {
                        console.log("Data added successfully:", result);
                      } else {
                        console.error("Error adding data:", result);
                      }
                    } catch (error) {
                      console.error("Error:", error);
                    }
                  });
                },
                onError: (err: any) => {
                  setModalVisibility(false);
                  setPaymentStatus("success");
                },
                is_final_capture: true,
              })
              .render("#paypal-button-container");
          }
        }, [])}
      ></div>
      <div id="paypal-label-container">
        <div id="paypal-message-container"></div>
      </div>
    </Box>
  );
}
