import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormItem } from "./FormItem/FormItem";
import { Button, ButtonGroup } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { FormItemText } from "../atoms/FormItemText/FormItemText";
import { FormItemDate } from "../atoms/FormItemDate/FormItemDate";
import { FormItemRadio } from "../atoms/FormItemRadio/FormItemRadio";
import { useFormContext } from "../../store/formContext";

export type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  phone: number;
  dateOfBirth: string;
  bond: number;
  address: string;
  employmentStatus: string;
  moveIn: string;
  landlordName: string;
  landlordEmail: string;
  landlordNumber: number;
  leaseDuration: number;
  utilityConnections: string;
};

export type InputTypes =
  | "firstName"
  | "lastName"
  | "email"
  | "phone"
  | "dateOfBirth"
  | "bond"
  | "address"
  | "employmentStatus"
  | "moveIn"
  | "landlordName"
  | "landlordEmail"
  | "landlordNumber"
  | "leaseDuration"
  | "utilityConnections";
export interface IRadioData {
  value: string;
  label: string;
}

const utilityConnectionsData: IRadioData[] = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];

const employmentStatusData: IRadioData[] = [
  {
    value: "full-time employed",
    label: "Full-time employed",
  },
  {
    value: "part-time employed",
    label: "Part-time employed",
  },
  {
    value: "self-employed",
    label: "Self-employed",
  },
  {
    value: "unemployed",
    label: "Unemployed",
  },
  {
    value: "student",
    label: "Student",
  },
];

export const Form = () => {
  const { setProgress, setModalVisibility, setFormData } = useFormContext();
  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
  } = useForm<Inputs>();
  const slidesListRef = React.useRef<any[]>([]);
  const slideControlRef = React.useRef<any>(null);
  const [currSlide, setCurrSlide] = useState<number>(0);
  const [lastSuccessSlide, setLastSuccessSlide] = useState<number>(0);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setFormData(data);
    setModalVisibility(true);
    try {
      const response = await fetch("/api/addToSheets", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      if (response.ok) {
        console.log("Data added successfully:", result);
      } else {
        console.error("Error adding data:", result);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getCurrentStepFields = (step: number) => {
    const stepFields: { [key: number]: (keyof Inputs)[] } = {
      0: ["firstName"],
      1: ["lastName"],
      2: ["email"],
      3: ["phone"],
      4: ["dateOfBirth"],
      5: ["bond"],
      6: ["address"],
      7: ["employmentStatus"],
      8: ["moveIn"],
      9: ["landlordName"],
      10: ["landlordEmail"],
      11: ["landlordNumber"],
      12: ["leaseDuration"],
      13: ["utilityConnections"],
    };
    return stepFields[step] || [];
  };

  useEffect(() => {
    setProgress((100 / 14) * (currSlide + 1));
  }, [currSlide]);

  const HandleOnOk = async () => {
    const isValid = await trigger(getCurrentStepFields(currSlide));
    if (!isValid) return false;

    const response = await HandleScrollNext();

    if (response) {
      setLastSuccessSlide((el) => {
        slidesListRef.current[el + 1].style.display = "flex";

        HandleScrollNext();

        return el + 1;
      });
    }
  };

  const HandleDownBtn = async () => {
    const isValid = await trigger(getCurrentStepFields(currSlide));
    if (!isValid) return false;

    await HandleScrollNext();
  };

  const HandleScrollNext = async () => {
    if (!slideControlRef.current) return false;

    const slideHeight = slidesListRef.current[0].offsetHeight;

    const nextSlideIndex = currSlide + 1;

    const nextScrollPosition = nextSlideIndex * slideHeight;

    slideControlRef.current.scrollTo({
      top: nextScrollPosition,
      behavior: "smooth",
    });

    setCurrSlide(nextSlideIndex);

    return true;
  };

  const HandleScrollPrevious = () => {
    if (!slideControlRef.current) return;

    const slideHeight = slidesListRef.current[0].offsetHeight;

    const previousSlideIndex = currSlide - 1;

    if (previousSlideIndex < 0) return;

    const previousScrollPosition = previousSlideIndex * slideHeight;

    slideControlRef.current.scrollTo({
      top: previousScrollPosition,
      behavior: "smooth",
    });

    setCurrSlide(previousSlideIndex);
  };

  const ScrollHandler = async () => {
    if (!slideControlRef.current) return;

    const scrollProgess = Math.trunc(
      Math.round(slideControlRef.current.scrollTop)
    );
    const slideHeight = slidesListRef.current[0].offsetHeight;

    if (scrollProgess % slideHeight === 0) {
      setCurrSlide(scrollProgess / slideHeight);
    }
  };

  useEffect(() => {
    if (slidesListRef.current.length === 0) return;
    slidesListRef.current.forEach(async (element: any, index: number) => {
      const resValueIsShow = index === 0;
      element.style.display = resValueIsShow ? "flex" : "none";
    });
  }, []);

  return (
    <div>
      <form
        className="baseForm"
        ref={slideControlRef}
        onScroll={ScrollHandler}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          className="formWrapEl"
          ref={(el) => (slidesListRef.current[0] = el)}
        >
          <FormItem label="First Name" itemIndex={1} scroll={HandleOnOk}>
            <FormItemText
              type="string"
              control={control}
              valueName="firstName"
              errors={errors}
            />
          </FormItem>
        </div>

        <div
          className="formWrapEl"
          ref={(el) => (slidesListRef.current[1] = el)}
        >
          <FormItem label="Last Name" itemIndex={2} scroll={HandleOnOk}>
            <FormItemText
              type="string"
              control={control}
              valueName="lastName"
              errors={errors}
            />
          </FormItem>
        </div>
        <div
          className="formWrapEl"
          ref={(el) => (slidesListRef.current[2] = el)}
        >
          <FormItem label="Email Address" itemIndex={3} scroll={HandleOnOk}>
            <FormItemText
              type="email"
              control={control}
              valueName="email"
              errors={errors}
            />
          </FormItem>
        </div>
        <div
          className="formWrapEl"
          ref={(el) => (slidesListRef.current[3] = el)}
        >
          <FormItem label="Phone Number" itemIndex={4} scroll={HandleOnOk}>
            <FormItemText
              type="phone"
              control={control}
              valueName="phone"
              errors={errors}
            />
          </FormItem>
        </div>
        <div
          className="formWrapEl"
          ref={(el) => (slidesListRef.current[4] = el)}
        >
          <FormItem label="Date of Birth" itemIndex={5} scroll={HandleOnOk}>
            <FormItemDate
              control={control}
              valueName="dateOfBirth"
              errors={errors}
            />
          </FormItem>
        </div>
        <div
          className="formWrapEl"
          ref={(el) => (slidesListRef.current[5] = el)}
        >
          <FormItem
            label="How much is your bond?"
            itemIndex={6}
            scroll={HandleOnOk}
          >
            <FormItemText
              type="number"
              control={control}
              valueName="bond"
              errors={errors}
            />
          </FormItem>
        </div>

        <div
          className="formWrapEl"
          ref={(el) => (slidesListRef.current[6] = el)}
        >
          <FormItem
            label="Where are you going to live?"
            subLabel="Full address"
            itemIndex={7}
            scroll={HandleOnOk}
          >
            <FormItemText
              type="address"
              control={control}
              valueName="address"
              errors={errors}
            />
          </FormItem>
        </div>

        <div
          className="formWrapEl"
          ref={(el) => (slidesListRef.current[7] = el)}
        >
          <FormItem label="Employment Status" itemIndex={8} scroll={HandleOnOk}>
            <FormItemRadio
              control={control}
              valueName="employmentStatus"
              errors={errors}
              data={employmentStatusData}
            />
          </FormItem>
        </div>

        <div
          className="formWrapEl"
          ref={(el) => (slidesListRef.current[8] = el)}
        >
          <FormItem
            label="Preferred Move-in Date"
            itemIndex={9}
            scroll={HandleOnOk}
          >
            <FormItemDate
              control={control}
              valueName="moveIn"
              errors={errors}
            />
          </FormItem>
        </div>

        <div
          className="formWrapEl"
          ref={(el) => (slidesListRef.current[9] = el)}
        >
          <FormItem
            label="Agent/Landlord Name or company"
            itemIndex={10}
            scroll={HandleOnOk}
          >
            <FormItemText
              type="string"
              control={control}
              valueName="landlordName"
              errors={errors}
            />
          </FormItem>
        </div>
        <div
          className="formWrapEl"
          ref={(el) => (slidesListRef.current[10] = el)}
        >
          <FormItem
            label="Agent/landlord email"
            itemIndex={11}
            scroll={HandleOnOk}
          >
            <FormItemText
              type="email"
              control={control}
              valueName="landlordEmail"
              errors={errors}
            />
          </FormItem>
        </div>
        <div
          className="formWrapEl"
          ref={(el) => (slidesListRef.current[11] = el)}
        >
          <FormItem
            label="Agent/Landlord phone number"
            itemIndex={12}
            scroll={HandleOnOk}
          >
            <FormItemText
              type="phone"
              control={control}
              valueName="landlordNumber"
              errors={errors}
            />
          </FormItem>
        </div>
        <div
          className="formWrapEl"
          ref={(el) => (slidesListRef.current[12] = el)}
        >
          <FormItem
            label="What is your lease duration?"
            itemIndex={13}
            scroll={HandleOnOk}
          >
            <FormItemText
              type="number"
              control={control}
              valueName="leaseDuration"
              errors={errors}
            />
          </FormItem>
        </div>
        <div
          className="formWrapEl"
          ref={(el) => (slidesListRef.current[13] = el)}
        >
          <FormItem
            label="Do you need any utility connections at your new place?"
            subLabel="either Internet, Gas, Electricity, Water, Insurance"
            itemIndex={14}
            isSubmit={true}
          >
            <FormItemRadio
              control={control}
              valueName="utilityConnections"
              errors={errors}
              data={utilityConnectionsData}
            />
          </FormItem>
        </div>
      </form>

      <ButtonGroup
        variant="contained"
        sx={{ position: "fixed", bottom: "25px", right: "25px" }}
        size="small"
      >
        <Button
          disabled={currSlide < 1 ? true : false}
          onClick={HandleScrollPrevious}
        >
          <KeyboardArrowUpRoundedIcon sx={{ width: "24px", height: "24px" }} />
        </Button>
        <Button
          disabled={
            currSlide >= slidesListRef.current.length - 1 ? true : false
          }
          onClick={HandleDownBtn}
        >
          <KeyboardArrowDownRoundedIcon
            sx={{ width: "24px", height: "24px" }}
          />
        </Button>
      </ButtonGroup>
    </div>
  );
};
