import { FormHelperText, TextField, useMediaQuery, useTheme } from '@mui/material'
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { InputTypes, Inputs } from '../../Form/Form';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import React from 'react'

interface IFormItemDate {
    control: Control<Inputs, any>;
    valueName: InputTypes;
    errors: FieldErrors<Inputs>;
}

export const FormItemDate: React.FC<IFormItemDate> = ({ control, valueName, errors }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(1000));

    return (
        <Controller
            name={`${valueName}`}
            control={control!}
            rules={{
                required: 'Date is required',
            }}
            render={({ field }) => (
                <>
                    <TextField
                        {...field}
                        sx={{ width: `${isMobile ? '100%' : '50%'}` }}
                        type="date"
                    />
                    {errors[valueName] && (
                        <FormHelperText
                            sx={{
                                backgroundColor: 'rgb(247, 230, 230)',
                                color: 'rgb(175, 4, 4)',
                                padding: '4px 12px 4px 8px',
                                borderRadius: '3px',
                                maxWidth: 'fit-content',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                                minHeight: '28px',
                            }}
                        >
                            <WarningRoundedIcon sx={{ width: '18px', height: '18px' }} />
                            {errors[valueName]?.message}
                        </FormHelperText>
                    )}
                </>
            )}
        />
    )
}

