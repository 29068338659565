import { Inputs } from "../components/Form/Form";

export interface IState {
    progress: number;
    modalVisibility: boolean;
    formData: Inputs | undefined;
    paymentStatus: 'none' | 'success' | 'rejected'
}

export enum Actions {
    SET_PROGRESS,
    SET_MODAL_VISIBILITY,
    SET_FORM_DATA,
    SET_PAYMENT_STATUS,
}

export interface IAction {
    type: Actions;
    payload?: any;
}